import React from "react"
import { graphql } from 'gatsby'
import Prose from "../components/utility/prose"

import Seo from "../components/seo"
import Content from "../components/content"
import moment from "moment-timezone"
import ShareLinks from "../components/utility/ShareLinks" 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'

const Post = ({data, location}) => {
  const post = data.strapiBlogPost
  const content = post.content
  const baseurl = `https://springcreekud.netlify.app`
  const publishedAt = post.publish_at !== null ? post.publish_at : post.publishedAt

  return (
  <>
    <Seo title={post.title} />
    <Prose>
      <h1 className="mb-2">{post.title}</h1>
      <small className="block mb-4">{moment.utc(publishedAt).tz("America/Chicago").format("MMMM D, YYYY h:mm a")}</small>
    </Prose>
    <div class="group inline-block relative pb-2 mb-14">
      <button class="block text-sm font-semibold lg:font-normal lg:text-sm uppercase lg:normal-case cursor-pointer text-gray-900 text-gray-700 bg-gray-200 group-hover:bg-gray-300 dark:bg-gray-600 dark:group-hover:bg-gray-800 dark:text-gray-300 dark:text-white lg:rounded-full mx-4 lg:mx-0 lg:px-4  py-2 border-gray-300 dark:border-gray-700 border-b-2 lg:border-0" aria-haspopup="true" aria-controls="dropdown-menu4">
      <span className="mr-2">Share</span>
        <FontAwesomeIcon icon={faAngleDown} />
      </button>
      <div class="absolute hidden group-hover:block left-0 mt-2 z-20 block w-48 pt-2 pb-1 bg-gray-300 dark:bg-gray-900 dark:text-gray-300 rounded-xl shadow-xl" id="dropdown-menu4" role="menu">
          <ShareLinks url={`${baseurl}${location.pathname}`} />
      </div>
      </div>
    <Content content={content}></Content>
  </>
)}

export default Post


export const postQuery = graphql`
  query postById($id: Int!) {
    strapiBlogPost(strapi_id: { eq: $id }) {
      id
      title
      publishedAt
      publish_at
      content {
        ... on STRAPI__COMPONENT_PAGE_ALERT {
          ...pageAlert
        }
        ... on STRAPI__COMPONENT_PAGE_CARDS {
          ...pageCards
        }
        ... on STRAPI__COMPONENT_PAGE_GALLERY {
          ...pageGallery
        }
        ... on STRAPI__COMPONENT_PAGE_IMAGE {
          ...pageImage
        }
        ... on STRAPI__COMPONENT_PAGE_IMAGE_TEXT {
          ...pageImageText
        }
        ... on STRAPI__COMPONENT_PAGE_TEXT {
          ...pageText
        }
        ... on STRAPI__COMPONENT_PAGE_TEXT_IMAGE {
          ...pageTextImage
        }
        ... on STRAPI__COMPONENT_PAGE_TEXT_TEXT {
          ...pageTextText
        }
        ... on STRAPI__COMPONENT_PAGE_WIDGET {
          ...pageWidget
        }
      }
    }
  }
`
